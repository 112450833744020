@import url("https://fonts.googleapis.com/css?family=Roboto:400,400i,700");

/* body {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
} */

a {
	text-decoration: none;
	font-family: Roboto, sans-serif;
	font-size: 1em;
	display: flex;
	flex-direction: column;
	justify-content: center;
	transition-duration: 0.2s;
	align-items: center;
	padding: 1rem 1.5rem;
}

img {
	transition-duration: 0.2s;
}

a:hover img {
	animation: left-edge 0.5s linear 1;
	filter: invert(72%) sepia(20%) saturate(747%) hue-rotate(174deg) brightness(96%) contrast(97%);
}

a:hover img {
	/* Set the color to royal brown */
	filter: invert(60%) sepia(100%) saturate(700%) hue-rotate(20deg) brightness(80%) contrast(120%);
}


img:hover .menu {
	box-shadow: 0px 10px 5px rgb(100, 187, 241, 0.1);
}

.menu {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	/* Fixed position */
	bottom: 0;
	/* Stick to the bottom */
	width: 100%;
	/* Take the full width of the screen */
	height: 60px;
	/* Set your desired height */
	padding: 0 15px;
	border-radius: 15px;
	background: rgb(255, 255, 255);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.dot {
	animation: dot-anim 8s linear infinite;
}

/* Animations */

@keyframes dot-anim {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.5);
	}

	100% {
		transform: scale(1);
	}
}

@keyframes left-edge {
	0% {
		transform: rotate(0deg);
	}

	35% {
		transform: rotate(-25deg);
	}

	75% {
		transform: rotate(25deg);
	}

	100% {
		transform: rotate(0deg);
	}
}