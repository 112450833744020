@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Elsie+Swash+Caps:wght@400;900&family=Rosarivo:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

/* Navbar Styles */
.navbar {
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  padding: 9px 129px;
  width: 100%;
  z-index: 999;

}

.navhead {
  font-family: "Elsie Swash Caps", serif;
  font-weight: 400;
  font-size: 36px;
  text-align: center;
}

.text1 {
  margin-left: 17px;
  margin-top: 25px;
}

.tagline {
  font-family: "Rosarivo", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}

.card {
  width: 100px;
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)); /* Soft background gradient */

  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}



.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.card {
  position: relative; /* Ensure child elements can be absolutely positioned /
  width: 220px;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  aspect-ratio: 1/1; / Maintain consistent aspect ratio */
}



.card img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the card without distortion */
}

.card p {
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%); /* Center the text */
  width: 100%;
  margin: 0;
  padding: 10px;
  color: white;
  font-size: 18px; /* Adjusted font size */
  font-family: 'Inter', sans-serif;

  font-weight: 400;
  text-align: center;


}

@media (max-width: 768px) {
  .card p {
    font-size: 14px; 
    font-weight: 200;

    font-family: 'Inter', sans-serif;
  }
}


/* Homepage.css */
.image-container {
  position: relative;
}

.category-name {
  font-family: 'Inter', sans-serif; /* Use Inter font /
  font-weight: 200; / Set to thin weight /
  font-style: normal; / Ensure no italic or oblique styles /
  -webkit-font-smoothing: antialiased; / Improve font rendering /
  -moz-osx-font-smoothing: grayscale; / Improve font rendering /
  position: absolute;
  bottom: 5px;
  left: 5px;
  color: white;
  font-size: 14px; / Adjust font size if needed */
  padding: 5px;
}

@media (min-width: 1024px) {
    .card-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
      gap: 20px;
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
    }

    .card {
      width: 100%;
      aspect-ratio: 1/1; /* Maintain square aspect ratio */
    }

    .card img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    /* .card:last-child {
      display: none;
    } */
    /* updated code */
    .card:last-child {
      display: block; /* Ensure the last card is visible */
  }
  
  }

@media (max-width: 768px) {
  .navbar {
    margin-top: 25px;

  }

  .navhead {
    font-family: "Elsie Swash Caps", serif;
    font-weight: 400;
    font-size: 36px;
    text-align: center;
  }

  .text1 {
    margin-left: 17px;
    margin-top: 25px;
  }

  .tagline {
    font-family: "Rosarivo", cursive;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .card {
    width: calc(33.33% - 20px);
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  }

  .card img {
    width: 100%;
    height: 106px;
    object-fit: cover;
    filter: brightness(0.5);
  }

  .card p {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    font-weight:500;
    font-family: 'Inter', sans-serif; /* Use Inter font */

    text-align: center;
  }
}
