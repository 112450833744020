.reviews_head {
    
    text-align: center; /* Center the heading text */
    width: 100%; /* Ensure the heading takes the full width of the container */
}
.review_start{
    
    font-size: 50px;
}
.ratingdiv{
    
    margin-top: 25px;
    padding: 20px;
}
.rating-starsdiv {
    
    color: #000000;
}

.add-review-btn {
    background-color: black;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}
.add-review-btn:hover {
    background-color: #333;
}

