/* Main container using grid layout */
.desc {
  display: grid; /* Use grid layout */
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Create flexible columns */
  gap: 15px; /* Add spacing between cards */
  justify-content: center; /* Center the grid */
  padding: 20px; /* Optional padding */
}

/* Card styles */
.cardesc {
  width: 100%; /* Full width of grid column */
  height: auto; /* Adjust height dynamically */
  border-radius: 15px;
  margin-bottom: 20px; /* Space below each card */
  background: #EAEAEA;
}

/* Card image styling */
.card-desc-image {
  width: 100%; /* Full width of the card */
  height: 300px; /* Fixed height for all images */
  border-radius: 10px;
  object-fit: cover; /* Ensures the image scales proportionally and fills the container */
}

/* Text below cards */
.cardesc_p {
  margin-left: 10px;
}

/* Star styles */
.stars {
  margin-left: 10px;
  display: flex;
}

.star {
  font-size: 20px;
  color: black;
}

/* Offer section */
.offer {
  margin-top: 12px;
  margin-bottom: 15px; /* Add spacing below the heading */
  text-align: center; /* Center align the heading */
}

.offertext {
  font-family: "Rosarivo", cursive;
  font-size: 31px;
  font-weight: 400;
  font-style: normal;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .desc {
    grid-template-columns: 1fr; /* Switch to single column layout */
    gap: 10px; /* Reduce spacing */
  }

  .cardesc {
    width: 100%; /* Full width on small screens */
    height: auto; /* Dynamic height */
    margin-bottom: 15px;
    background: #EAEAEA;
  }

  .card-desc-image {
    width: 100%; /* Full width of the card */
    height: 200px; /* Consistent height */
    border-radius: 10px;
    object-fit: cover; /* Ensure consistency */
  }

  .offertext {
    font-family: "Rosarivo", cursive;
    font-size: 21px; /* Smaller font for mobile */
    font-weight: 400;
    font-style: normal;
  }
}
