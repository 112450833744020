.review_container {
    display: flex;
    align-items: center; /* Align items vertically in the center */
    justify-content: space-between; /* Distribute space between items */
    width: 100%; /* Ensure the container takes the full width of the screen */
    padding-top: 20px; /* Add some space at the top of the container */
}
.profile_head {
    text-align: center; /* Center the heading text */
    width: 100%; /* Ensure the heading takes the full width of the container */
}
.backbutton{
    display: flex;
}
.rating {
    display: inline-block;
  }
  
  .rating input {
    display: none;
  }
  
  .rating label {
    
    float: right;
    cursor: pointer;
    color: #ccc;
    transition: color 0.3s;
  }
  
  .rating label:before {
    content: '\2605';
    font-size: 30px;
  }
  
  .rating input:checked ~ label,
  .rating label:hover,
  .rating label:hover ~ label {
    color: #000000;
    transition: color 0.3s;
  }

  .rating-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.feedback-textarea {
    width: 100%;
    height: 200px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
}
.rating-description{
    margin-top: 20px;
    padding: 20px;
}
.rating-head{
    text-align: center;
    margin-bottom: 15px;
}

.review_button {
    margin-top: 35px;
    width: 371px;
    height: 55px;
    border: 3px solid #8f9093;
    border-radius: 45px;
    color: white;
    transition: all 0.3s;
    cursor: pointer;
    background: rgb(0, 0, 0);
    font-size: 1.2em;
    font-weight: 550;
}
.review_button:hover {
    background: #ede7e0;
    color: rgb(0, 0, 0);
    
  }
  .container1111{
    
        text-align: center; /* Center all text-align elements */
        height: 100vh;
    
  }
  .review-back-button{
    background-color: white;
    border: none;
    outline: none;
  }